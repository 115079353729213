import React, { useState, useCallback } from 'react';

import { LOCAL_STORAGE } from 'consts';
import { getStorageObject, setStorageObject, clearStorage } from 'utils/localStorage';
import { login as loginRequest, logout as logoutRequest, LoginPayload } from './authRequests';

const { AUTH_STORAGE_KEY, PROFILE_STORAGE_KEY, SAVED_ITEMS_STORAGE_KEY } = LOCAL_STORAGE;

interface AuthContextValues {
  authorized: boolean;
  authData?: AuthData;
  profile?: ProfileData;
  savedItems?: SavedItemsData;
  setAuthData: (value: AuthData) => void;
  setProfile: (value: ProfileData) => void;
  setSavedItems: (value: SavedItemsData) => void;
  login: (data: LoginPayload) => Promise<void>;
  loginManually: (authData: LoginData) => void;
  logout: () => Promise<void>;
  logoutManually: () => void;
}

const AuthContext = React.createContext({} as AuthContextValues);

export const AuthContextWrapper = ({ children }) => {
  const [authData, dispatchSetAuthData] = useState<AuthData | undefined>(
    getStorageObject(AUTH_STORAGE_KEY)
  );
  const [profile, dispatchSetProfile] = useState<ProfileData | undefined>(
    getStorageObject(PROFILE_STORAGE_KEY)
  );
  const [savedItems, dispatchSetSavedItems] = useState<SavedItemsData | undefined>(
    getStorageObject(SAVED_ITEMS_STORAGE_KEY)
  );
  const [authorized, setAuthorized] = useState(Boolean(authData));

  const setAuthData = (value: AuthData) => {
    setStorageObject(AUTH_STORAGE_KEY, value);
    dispatchSetAuthData(value);
  };

  const setProfile = (value: ProfileData) => {
    setStorageObject(PROFILE_STORAGE_KEY, value);
    dispatchSetProfile(value);
  };

  const setSavedItems = (value: SavedItemsData) => {
    setStorageObject(SAVED_ITEMS_STORAGE_KEY, value);
    dispatchSetSavedItems(value);
  };

  const setAuthDataStorage = useCallback((data: LoginData) => {
    setAuthData({ ...data.authData, admin: data.admin });
    setProfile(data.profile);
    // setSavedItems(data.savedItems);
  }, []);

  const login = useCallback(
    async data => {
      const response = await loginRequest(data);
      setAuthDataStorage(response);
      setAuthorized(true);
    },
    [setAuthDataStorage]
  );

  const logout = useCallback(async () => {
    await logoutRequest();
    clearStorage();
    setAuthorized(false);
  }, []);

  const loginManually = (authData: LoginData) => {
    setAuthDataStorage(authData);
    setAuthorized(true);
  };

  const logoutManually = () => {
    clearStorage();
    setAuthorized(false);
  };

  const values: AuthContextValues = {
    authorized,
    authData,
    setAuthData,
    profile,
    setProfile,
    savedItems,
    setSavedItems,
    login,
    loginManually,
    logout,
    logoutManually
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContext;
