import { fetch } from 'utils/fetch';
import keysToCamel from 'utils/keysToCamel';
import { getStorageObject, clearStorage } from 'utils/localStorage';
import { urlEncodeBody } from 'utils/requests';

export type LoginPayload = {
  email: string;
  password: string;
};

export async function login(payload: LoginPayload): Promise<LoginData> {
  const response = await fetch('/auth/login', {
    method: 'POST',
    body: urlEncodeBody(payload)
  });

  const data = await response.json();

  return keysToCamel(data);
}

export async function logout() {
  const authData = getStorageObject('authData');
  if (!authData?.refreshToken) {
    clearStorage();
    throw Error('No token');
  }

  await fetch('/auth/logout', {
    method: 'POST',
    body: urlEncodeBody({ refresh_token: authData!.refreshToken })
  });
}
