/* eslint-disable import/extensions */
/* eslint-disable react/jsx-filename-extension */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { AuthContextWrapper } from './src/auth';
import { ThemeWrapper } from './src/styles';

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }) => {
  return (
    <ThemeWrapper>
      <AuthContextWrapper>
        {element}
        <div id="modal-root" />
      </AuthContextWrapper>
    </ThemeWrapper>
  );
};