import merge from 'lodash/merge';
import { navigate } from 'gatsby';

import { LOCAL_STORAGE } from 'consts';
import { getStorageObject, setStorageObject, clearStorage } from 'utils/localStorage';
import { urlEncodeBody } from '../requests';
import { fetchJSON, configureRefreshFetch, tryParseJSON } from './refresh-fetch';
import { info } from 'sitedata';


const { apiUrl } = info;

type RequestInitWithQuery = RequestInit & { query?: string };

const getAuthData = (): AuthData => getStorageObject(LOCAL_STORAGE.AUTH_STORAGE_KEY);

function FetchException(this: any, response: Response, responseData?: any) {
  this.message = response.statusText;
  this.status = response.status;
  this.response = responseData;
}

const handleError = async (response: Response) => {
  const data = tryParseJSON(await response.text());
  throw new FetchException(response, data);
};

export const fetch = async (input: string, init?: RequestInitWithQuery) => {
  const url = new URL(input, apiUrl);
  if (init?.query) url.search = init.query;
  const response = await window.fetch(url as any, init);
  if (!response.ok) await handleError(response);

  return response;
};

export const fetchWithToken = configureRefreshFetch({
  fetch: async (input: string, init?: RequestInitWithQuery): Promise<Response> => {
    const authData = getAuthData();

    let optionsWithToken = init;
    if (authData) {
      optionsWithToken = merge({}, init, {
        headers: { Authorization: `Bearer ${authData.accessToken}` }
      });
    }

    const url = new URL(input, apiUrl);
    if (init?.query) url.search = init.query;

    const { response } = await fetchJSON(url as any, optionsWithToken);
    if (!response.ok) await handleError(response);

    return response;
  },

  shouldRefreshToken: error => error.status === 401,

  refreshToken: async (navigateToAuthIf401: boolean) => {
    try {
      const authData = getAuthData();
      if (!authData) throw Error('No token in storage');
      const response = await fetch('/auth/refresh', {
        method: 'POST',
        body: urlEncodeBody({ refresh_token: `${authData.refreshToken}` })
      });

      const data = await response.json();
      setStorageObject(LOCAL_STORAGE.AUTH_STORAGE_KEY, {
        ...authData,
        accessToken: data.access_token,
        expiresIn: data.expires_in
      });
    } catch (error) {
      // Clear token and continue with the Promise catch chain
      clearStorage();
      if (navigateToAuthIf401) {
        const url = new URL(window.location.href);
        if (!url.searchParams.has('auth')) url.searchParams.append('auth', '1');
        navigate(url.toString());
      }
      throw error;
    }
  }
});
