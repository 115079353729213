const {info} = require( "../sitedata");
// import {info} from "../sitedata";

exports.IMAGE_SIZES = {
  xl: '1200x800',
  large: '728x464',
  medium: '320x240',
  small: '72x72'
};

exports.LOCAL_STORAGE = {
  VERSION_STORAGE_KEY: 'version',
  AUTH_STORAGE_KEY: 'authData',
  PROFILE_STORAGE_KEY: 'profileData',
  SAVED_ITEMS_STORAGE_KEY: 'savedItemsData'
};

exports.SITE_URL = info.siteUrl;

exports.REGEXP = {
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  PASSWORD: /^[A-Za-z\d{}()[\]#:;^,.?!|&_`~@$%/\\=+\-*"']{8,32}$/,
  ZIP_CODE: /(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}/,
  NAME: /^[\w\u0100-\u017F\u0180-\u024F\u0080-\u00FF ,.'-][^0-9]+$/
};

exports.STATES = {
  AL: 'ALABAMA',
  AK: 'ALASKA',
  AS: 'AMERICAN SAMOA',
  AZ: 'ARIZONA',
  AR: 'ARKANSAS',
  CA: 'CALIFORNIA',
  CO: 'COLORADO',
  CT: 'CONNECTICUT',
  DE: 'DELAWARE',
  DC: 'DISTRICT OF COLUMBIA',
  FM: 'FEDERATED STATES OF MICRONESIA',
  FL: 'FLORIDA',
  GA: 'GEORGIA',
  GU: 'GUAM GU',
  HI: 'HAWAII',
  ID: 'IDAHO',
  IL: 'ILLINOIS',
  IN: 'INDIANA',
  IA: 'IOWA',
  KS: 'KANSAS',
  KY: 'KENTUCKY',
  LA: 'LOUISIANA',
  ME: 'MAINE',
  MH: 'MARSHALL ISLANDS',
  MD: 'MARYLAND',
  MA: 'MASSACHUSETTS',
  MI: 'MICHIGAN',
  MN: 'MINNESOTA',
  MS: 'MISSISSIPPI',
  MO: 'MISSOURI',
  MT: 'MONTANA',
  NE: 'NEBRASKA',
  NV: 'NEVADA',
  NH: 'NEW HAMPSHIRE',
  NJ: 'NEW JERSEY',
  NM: 'NEW MEXICO',
  NY: 'NEW YORK',
  NC: 'NORTH CAROLINA',
  ND: 'NORTH DAKOTA',
  MP: 'NORTHERN MARIANA ISLANDS',
  OH: 'OHIO',
  OK: 'OKLAHOMA',
  OR: 'OREGON',
  PW: 'PALAU',
  PA: 'PENNSYLVANIA',
  PR: 'PUERTO RICO',
  RI: 'RHODE ISLAND',
  SC: 'SOUTH CAROLINA',
  SD: 'SOUTH DAKOTA',
  TN: 'TENNESSEE',
  TX: 'TEXAS',
  UT: 'UTAH',
  VT: 'VERMONT',
  VI: 'VIRGIN ISLANDS',
  VA: 'VIRGINIA',
  WA: 'WASHINGTON',
  WV: 'WEST VIRGINIA',
  WI: 'WISCONSIN',
  WY: 'WYOMING'
};